import * as React from "react"
import { Helmet } from "react-helmet"
import { LoginForm } from "../components/Forms/Login"

const LoginPage = () => (
  <>
    <Helmet>
      <meta name="title" content="Micropore Technologies - Login" />
      <meta
        name="description"
        content="Log into your customer account to view detailed product information."
      />
    </Helmet>
    <LoginForm />
  </>
)

export default LoginPage
